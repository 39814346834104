











import {defineComponent, useContext} from "@nuxtjs/composition-api";

export default defineComponent({
  name: 'AvailableBrands',
  props: {
    availableBrandsIds: {
      type: Array,
      default: []
    },
    product: {
      type: Object,
      default: ()=>{}
    }
  },
  setup () {
    const { $config } = useContext();
    const baseUrl = $config.baseUrl || 'http://localhost:3000'
    return {
      baseUrl
    }
  }
})
